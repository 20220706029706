<template>
    <div class="railway">
        <!-- 陆运询柜 -->
        <div class="railway_Top">
            <div class="backgroundPicture"></div>
        </div>
        <!-- 询价 -->
        <Railway_Component></Railway_Component>
        <Footer_Compontent></Footer_Compontent>
    </div>
</template>

<script>
// import Inquiry_Component from '../../components/EnquiryCabinet/Inquiry_Component.vue'
import Railway_Component from '../../components/EnquiryCabinet/Railway_Component.vue'
import Footer_Compontent from '../../components/Footer_Compontent.vue'
export default {
    name: "OceanShipping",
    components: { Railway_Component, Footer_Compontent },

}
</script>

<style lang="scss" scoped>
.railway {
    width: 100%;

    .railway_Top {
        width: 100%;
        // height: 363px;

        .backgroundPicture {
            height: 158px;
            background: url('../../assets/homePage/salesVolume.png');
        }

        .inquiry {
            width: 1200px;
            // height: 265px;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            margin: 0 auto;
            border: 1px solid;
        }
    }
}

.footer {
    margin-top: 133px;
}
</style>